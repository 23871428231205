import React from 'react'
import Container from "../../components/MainContainerWithWindow/Container";
import {ErrorMessagePage} from "../../components/ErrorWrapper/ErrorMessagePage";

export const ForbiddenError = () => {
  return (
    <Container>
      <ErrorMessagePage errorCode={'403'} errorText={'Нет доступа'} />
    </Container>
  )
}
