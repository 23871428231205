import styled from "styled-components";
import {TButtonProps} from "./Contact";

export const Block = styled.form`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 32px;
    border-radius: 10px;
    background: rgba(24, 144, 255, 0.05);
    padding: 40px;
    h3 {
        color: #2C2E35;
        font-size: 30px;
        font-weight: 400;
        line-height: 26px; 
    }
    @media (max-width: 1024px) {
        h3 {
            font-size: 20px;
        }
    }
    @media (max-width: 768px) {
        gap : 20px;
        padding: 24px;
    }
`
export const TopBlock = styled.div`
    display: flex;
    gap : 40px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`
export const LeftContainer = styled.div`
    width: 100%;
    display: flex;
    align-content: flex-start;
    gap : 20px;
    flex-wrap: wrap;
    div {
        width: 48%
    }
    @media (max-width: 1024px) {
        flex-direction: column;
        flex-wrap: nowrap;
        div {
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        gap : 14px;
    }
    
`
export const Icon = styled.img`
    width: 318px;
    @media (max-width: 1024px) {
        width: 300px;
    }
    @media (max-width: 768px) {
        width: 100%
    }
`


export const Info = styled.span`
    color: #696E7E;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    gap : 14px;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
        gap : 20px;
        button {
            width: 100%;
        }
    }
`

export const Button = styled.button<TButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap : 4px;
    padding: 8px 16px;
    border : none;
    background:  ${({ send }) => send ? 'rgba(77, 184, 75, 0.15)' : '#1890FF'};
    color: ${({ send }) => send ? '#4DB84B' : '#FFF'};
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
    height: 44px;
    transition: 0.5s;
    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
`

