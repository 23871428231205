// @ts-ignore
import styles from './Loading.module.css'

export const Loading = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.animated}>
          <img src={'/loading-icon.png'} alt={'loading'} />
        </div>
        <img className={styles.logo} src={'/loading-logo.png'} alt={'logo'} />
      </div>
    </div>
  )
}
