import styled from "styled-components";

export const Title = styled.div`
    display: flex;
    align-items: start;
    margin-bottom: 40px;
    width: 80%;
    h1 {
        width: 304px;
        color: #2C2E35;
        font-size: 35px;
        font-weight: 400;
    }
    p {
        color: #696E7E;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
    }
    @media (max-width: 1024px) {
        width: 100%;
        p {
            font-size: 18px;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        & {
            flex-direction: column;
            gap : 16px;
        }
        h1 {
            width: fit-content;
            font-size: 22px;
            line-height: 26px; 
        }
        p {
            font-size: 16px;
            line-height: 24px; 
        }
    }
`