import Title from "../common/Title/Title";
import * as UI from './styled'
import {WORKING_ID} from "../../../../utils/id";
export const Working = () => {
    return (
        <div id={WORKING_ID}>
            <Title titleText={'Деятельность'} infoText={'Направления, в которых мы работаем'} style={{alignItems : "center"}}/>
            <UI.Blocks>
                <UI.FilledBlock>
                    <h4>E-COM, P2P, C2C, BOT-Рау</h4>
                    <p>Технические решения для онлайн-торговли, адаптивные в работе с разными валютами</p>
                </UI.FilledBlock>
                <UI.Block>
                    <h3>Доступные страны</h3>
                    <div>
                        <p><img src={'/russiaFlagIcon.svg'} alt={'russia'}/>Россия</p>
                        <p><img src={'/kazakhstanFlagIcon.svg'} alt={'kazakhstan'}/>Казахстан</p>
                        <p><img src={'/belarusFlagIcon.svg'} alt={'belarus'}/>Беларусь</p>
                        <p><img src={'/azerbaijanFlagIcon.svg'} alt={'azerbaijan'}/>Азербайджан</p>
                        <p><img src={'/uzbekistanFlagIcon.svg'} alt={'uzbekistan'}/>Узбекистан</p>
                        <span>Остальные страны по запросу.</span>
                    </div>
                </UI.Block>
                <UI.Block>
                    <h3>Платежные системы</h3>
                    <div>
                        <p><img src={'/visaIcon.svg'} alt={'visa'}/></p>
                        <p><img src={'/mastercardIcon.svg'} alt={'master card'}/></p>
                        <p><img src={'/mirIcon.svg'} alt={'mir'}/></p>
                        <p><img src={'/humoIcon.svg'} alt={'humo'}/></p>
                        <p><img src={'/uzcardIcon.svg'} alt={'uzcard'}/></p>
                        <p><img src={'/belcardIcon.svg'} alt={'belcard'}/></p>
                        <p><img src={'/msdpIcon.svg'} alt={'msdp'}/><img src={'/msdpName.svg'} alt={'msdp name'}/></p>
                        <p><img src={'/cmkIcon.svg'} alt={'cmk'}/><img src={'/cmkName.svg'} alt={'cmk'}/></p>
                        <span>и др.</span>
                    </div>
                </UI.Block>
            </UI.Blocks>
        </div>
    )
}
export default Working