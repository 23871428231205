import styled from "styled-components";

export const Block = styled.div`
    padding: 49px;
    border-radius: 10px;
    background: rgba(24, 144, 255, 0.05);
    display: flex;
    gap : 13px;
    div {
        width: 25%;
        display: flex;
        flex-direction: column;
        gap : 14px;
        img  {
            width: 44px;
            height: 44px;
        }
        p {
            color: #2C2E35;
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
        }
        span  {
            color: #696E7E;
            font-size: 18px;
            font-weight: 400;
            line-height: 26px; 
        }
    }
    @media (max-width: 1024px) {
        gap : 20px;
        padding: 30px;
        div { 
            width: 100%;
            gap : 8px;
            p {
                font-size: 18px;
                line-height: 26px;
            }
            span {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
        gap : 32px;
        padding: 24px;
        div {
            p {
                font-size: 20px;
                line-height: 26px;
            }
            span {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
`