
interface ITimerProps {
  seconds: number
  className?: string
  endsWith?: () => void
}

const Timer = (props: ITimerProps) => {
  const { seconds, className, endsWith } = props
  return (
    <p className={className}>
      {`${Math.floor(seconds / 60)}`.padStart(2, '0')}:{`${seconds % 60}`.padStart(2, '0')}
    </p>
  )
}

export default Timer
