import styled from "styled-components";

export const Container = styled.div`
    padding: 60px 80px 192px;
    display: flex;
    flex-direction: column;
    gap : 100px;
    @media (max-width: 1024px) {
        & {
            padding: 60px 60px 140px;
            gap : 120px;
        }
    }
    @media (max-width: 768px) {
        & {
            gap : 48px;
            padding: 64px 16px 128px;
        }
    }
`