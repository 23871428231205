// @ts-ignore
import styles from './ControlledInput.module.css'
import {RegisterOptions, useFormContext} from 'react-hook-form'

export interface IInputProps {
  title?: string
  placeholder: string
  name: string
  rules?: RegisterOptions
  type?: 'password' | 'text'
  imgLeft?: string
  imgLeftWidth?: number
  imgRight?: string
  imgRightWidth?: number
  imgRightAction?: () => void
  required?: boolean
  blockStyle?: React.CSSProperties
  inputStyle?: React.CSSProperties
  defaultValue?: string
  errorText? : string
  transform?: (text: string | undefined) => string | undefined | number
}


export const ControlledInput = (props: IInputProps) => {
  const {
    title,
    placeholder,
    name,
    rules = {},
    type = 'text',
    imgRight,
    imgRightWidth = 0,
    imgRightAction,
    imgLeft,
    imgLeftWidth = 0,
    required = false,
    blockStyle,
    transform,
    defaultValue = '',
    inputStyle,
    errorText = ''
  } = props
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext()
  const transformInput = (text: string | undefined) => {
    if (transform && text) {
      setValue(name, transform(text))
    }
  }
  return (
    <div className={styles.inputBlock} style={blockStyle}>
      <div className={styles.title}>
        {required && <span style={{ color: '#FE5B37' }}>*</span>}
        {title}
      </div>
      <div className={styles.input}>
        {imgLeft && (
          <img
            src={imgLeft}
            className={styles.imgLeft}
            style={{ width: imgLeftWidth }}
            alt={imgLeft}
          />
        )}
        <input
          style={imgLeft ? { paddingLeft: `${imgLeftWidth + 22}px` } : inputStyle}
          className={errors?.[name] ? `${styles.field} ${styles.error}` : styles.field}
          type={type}
          placeholder={placeholder}
          {...register(name, rules)}
          defaultValue={defaultValue}
          onChange={(e) => {
            transform && transformInput(e.target.value)
          }}
        />
        {imgRight && (
          <img
            src={imgRight}
            className={styles.imgRight}
            style={{ width: imgRightWidth }}
            onClick={imgRightAction}
            alt={imgRight}
          />
        )}
      </div>
      <div className={styles.errorSpan}>
        {errors?.[name] && errorText}
      </div>
    </div>
  )
}
