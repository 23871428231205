import styled from "styled-components";

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 80px;
    img  {
        width: 140px;
        height: 27px;
    }
    @media (max-width: 1024px) {
        & {
            padding: 18px 60px;
        }
    }
    @media (max-width: 768px) {
        & {
            padding: 16px;
        }
    }
`

export const Menu = styled.div`
    display: flex;
    gap : 80px;
    
    font-size: 18px;
    font-weight: 400;
    line-height: 26px; 
    transition: 0.5s;
    a {
        color: #000;
        text-decoration: none;
    }
    a:hover {
        cursor: pointer;
        opacity: 0.5;
    }
    @media (max-width: 1024px) {
        & {
            gap : 32px;
            font-size: 16px;
            line-height: 24px; 
        }
    }
    @media(max-width: 768px) {
        & {
            display: none;
        }
    }
`

export const Link = styled.a`
    border-radius: 10px;
    text-decoration: none;
    background: rgba(24, 144, 255, 0.05);
    padding: 8px 16px;
    color: #1890FF;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    transition: 0.5s;
    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`