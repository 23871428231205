import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getMerchantTokenFromLocalStorage } from '../../utils/functions/getToken'
import { type TItem } from '../../components/Selector/Selector'
import { type TCurrencyResponse } from '../types/CurrencyTypes'
import {backendUrl} from "../../utils/url";

export const CurrencyApi = createApi({
  reducerPath: 'CurrencyApi',
  tagTypes: ['Currency'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${backendUrl}`,
    headers: { Authorization: `Token ${getMerchantTokenFromLocalStorage()}` },
  }),
  endpoints: (build) => ({
    getCurrency: build.query<TItem[], void>({
      query: () => ({
        url: '/currency_dropdown_values/',
        method: 'GET',
      }),
      transformResponse: (response: TCurrencyResponse[]) => {
        return response.map((item: TCurrencyResponse) => ({
          id: item.id.toString(),
          name: item.value,
        }))
      },
    }),
    getCheckActivePayments: build.query({
      query: () => ({
        url: '/requisite/check_active_payments_type/',
        method: 'GET'
      })
    })
  }),
})

export const { useGetCurrencyQuery, useGetCheckActivePaymentsQuery } = CurrencyApi
