import React from 'react'
import Container from "../../components/MainContainerWithWindow/Container";
import {ErrorMessagePage} from "../../components/ErrorWrapper/ErrorMessagePage";

export const NotFoundError = () => {
  return (
    <Container>
      <ErrorMessagePage errorCode={'404'} errorText={'Страница не найдена'} />
    </Container>
  )
}
