// @ts-ignore
import styles from './SuccessTransfer.module.css'
import { Button } from '../../../../../components/Button/Button'
import { TStatusProps } from '../../TransferStatus'
import Container from "../../../../../components/MainContainerWithWindow/Container";

export const SuccessTransfer = (props : TStatusProps) => {
  const {url} = props
  const handleNavigate = () => {
    window.location.href = url;
  }
  return (
    <Container>
      <div className={styles.wrapper}>
        <h1>Спасибо! Оплата прошла успешно</h1>
        <Button
            action={() => handleNavigate()}
            text={'Вернуться на сайт партнера'}
            style={{width: '100%', marginBottom: '12px'}}
        />
      </div>
    </Container>
  )
}
