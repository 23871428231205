import styled from "styled-components";

export const Container = styled.div`
    a, button {
        width: 100%;
        padding: 8px 16px;
        border : none;
        text-decoration: none;
        background: #1890FF;
        color: #FFF;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        white-space: nowrap;
    }
    height: 44px; 
    width: 173px;
    transition: 0.5s;
    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
`
