import Title from "../common/Title/Title";
import * as UI from './styled'
import Button from "../common/Button/Button";
import {INTEGRATION_ID} from "../../../../utils/id";

export const Integrations = () => {
    return (
        <div id={INTEGRATION_ID}>
            <Title titleText={'Интеграция'} infoText={'Как происходит взаимодействие с вашим проектом'} style={{alignItems : "center"}}/>
            <UI.Blocks>
                <UI.Item>
                    <img src={'/oneIcon.png'} alt={'1'} className={'number'}/>
                    <h4>Знакомство</h4>
                    <p>На онлайн-встрече знакомимся с вашим проектом</p>
                    <UI.Contact>
                        <Button/>
                        <img src={'/hands.png'} alt={'hands'}/>
                    </UI.Contact>

                </UI.Item>
                <UI.Item>
                    <img src={'/twoIcon.png'} alt={'2'} className={'number'}/>
                    <h4>Интеграция</h4>
                    <p>Интегрируйтесь с нами, используя метод H2H, или работайте с нашей платёжной платформой.</p>
                    <UI.Info>
                        <img src={'/parkIcon.svg'} alt={'park'}/>
                        При методе интеграции H2H клиент не перенаправляется при совершении платежа. Этот метод позволяет повысить конверсию даже в рамках альтернативных платёжных систем.
                    </UI.Info>
                </UI.Item>
                <UI.Item>
                    <img src={'/threeIcon.png'} alt={'3'} className={'number'}/>
                    <h4>Управление</h4>
                    <p>Контролируйте статистику платежей и выводите средства через личный торговый счёт на платформе.</p>
                    <UI.Lk src={'/lkIcon.svg'} alt={'lk'}/>
                </UI.Item>
            </UI.Blocks>
        </div>
    )
}
export default Integrations