import * as LUI from './dispute.styled'
import {ChangeEvent, useEffect, useRef, useState} from "react";
import {Button} from "../../components/Button/Button";
import {
  useCheckDisputeQuery,
  useCheckMerchantDisputeQuery,
  usePostDisputeMutation
} from "../../redux/services/DisputesApi";
import Modal from "../../components/ModalDefault/Modal";
import setFavicon from '../../utils/setFavIcon';

const DisputePayment = () => {
  const [idInput, setIdInput] = useState<string>('');
  const [sumInput, setSumInput] = useState<string>();
  const [sumOrder, setSumOrder] = useState<number>()
  const [file1, setFile1] = useState<File | null>(null);
  const [file2, setFile2] = useState<File | null>(null);
  const ref1 = useRef<HTMLInputElement>(null);
  const ref2 = useRef<HTMLInputElement>(null);
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);
  const [shouldFetchSecond, setShouldFetchSecond] = useState<boolean>(false);
  const [comment, setComment] = useState('');
  const [createDispute] = usePostDisputeMutation();
  const {data: dispute, refetch: checkDispute} = useCheckDisputeQuery(idInput || '', {
    skip: !shouldFetch,
  });

  const {data: disputeMerchant, refetch: checkMerchant} = useCheckMerchantDisputeQuery(idInput || '', {
    skip: !shouldFetchSecond,
  });

  const handleFileInput1 = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      setFile1(selectedFiles[0]);
    }
  };

  const handleFileInput2 = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      setFile2(selectedFiles[0]);
    }
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
    event.currentTarget.classList.add('dragover');
  };

  const handleDragLeave = (event: any) => {
    event.currentTarget.classList.remove('dragover');
  };

  const handleDrop1 = (event: any) => {
    event.preventDefault();
    event.currentTarget.classList.remove('dragover');
    const selectedFiles = event.dataTransfer.files;
    if (selectedFiles && selectedFiles.length > 0) {
      setFile1(selectedFiles[0]);
      if (ref1.current) ref1.current.files = selectedFiles;
    }
  };

  const handleDrop2 = (event: any) => {
    event.preventDefault();
    event.currentTarget.classList.remove('dragover');
    const selectedFiles = event.dataTransfer.files;
    if (selectedFiles && selectedFiles.length > 0) {
      setFile2(selectedFiles[0]);
      if (ref2.current) ref2.current.files = selectedFiles;
    }
  };

  const isUUID = (input: string): boolean => {
    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return uuidRegex.test(input);
  };

  const modalHeaderText: string = dispute?.order_status === 'TO' ? 'По сделке вышло время.' : 'Сделка уже успешна'

  const handleSubmit = async (status?: string) => {
    if (!idInput || (!file1 && !file2)) {
      alert('Пожалуйста, заполните ID платежа и загрузите файл.');
      return;
    }
    const formData = new FormData();
    if (isUUID(idInput.trim())) {
      formData.append('order', idInput.trim());
    } else {
      formData.append('merchant_order_id', idInput.trim());
    }

    if (sumInput) {
      formData.append('new_real_income', sumInput)
    } else {
      formData.append('new_real_income', '0')
    }
    if (status) {
      formData.append('status_approve', status);
    }
    if (file1) {
      formData.append('files', file1);
    }
    if (file2) {
      formData.append('files', file2);
    }
    if (comment) {
      formData.append('comment', comment)
    }
    setModalActive(false)

    try {
      await createDispute(formData).unwrap().then(() => {
        alert('Диспут успешно создан!');
      });
      setIdInput('');
      setFile1(null);
      setFile2(null);
      setSumOrder(undefined);
      setSumInput(undefined);
      setComment('')
      if (ref1.current) ref1.current.value = '';
      if (ref2.current) ref2.current.value = '';
    } catch (error) {
      setModalActive(false)
      // @ts-ignore
      alert(error?.status === 400 && error?.data.new_real_income.length > 0 ? error?.data.new_real_income[0] : 'Произошла ошибка при создании диспута');
    }
  };

  const handleCheckDispute = async () => {
    if (isUUID(idInput.trim())) {
      setShouldFetch(true);
    } else {
      setShouldFetchSecond(true)
    }
  };

  useEffect(() => {
    const fetchDispute = async () => {
      if (shouldFetch) {
        try {
          const response = await checkDispute().unwrap();
          setSumOrder(response.real_income)
          if (response && ['AA', 'SF', 'FR', 'AAD', 'TO'].includes(response.order_status)) {
            console.log('res[p', response)
            setModalActive(true);
          } else {
            handleSubmit();
          }
        } catch (error) {
          console.log(error)
          alert('Произошла ошибка при проверке диспута.');
        } finally {
          setShouldFetch(false);
        }
      }
    };
    fetchDispute();
  }, [shouldFetch, checkDispute]);

  useEffect(() => {
    const fetchDispute = async () => {
      if (shouldFetchSecond) {
        try {
          const response = await checkMerchant().unwrap();
          setSumOrder(response.real_income)
          if (response && ['AA', 'SF', 'FR', 'AAD', 'TO'].includes(response.order_status)) {
            setModalActive(true);
          } else {
            handleSubmit();
          }
        } catch (error) {
          alert('Произошла ошибка при проверке диспута.');
        } finally {
          setShouldFetchSecond(false);
        }
      }
    };
    fetchDispute();
  }, [shouldFetchSecond, checkMerchant]);

  useEffect(() => {
    setFavicon('/favicon_dispute.svg');
  }, []);

  return (
      <>
        {modalActive && <Modal active={modalActive} setActive={setModalActive} style={{}} className={''}>
            <LUI.TextForModal>
              <h1>{`${modalHeaderText} Сумма ${sumOrder}`}</h1>
              <h2>Вы уверены, что хотите создать спор для пересчета сделки?</h2>
                <LUI.DisputeCardInput>
                    <label>Сумма платежа</label>
                    <input
                        type="number"
                        value={sumInput}
                        placeholder="Введите сумму"
                        onChange={(e) => setSumInput(e.target.value)}
                    />
                </LUI.DisputeCardInput>
                <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%', gap: '8px'}}>
                    <Button text={'Нет'} filled={false} style={{padding: '12px 25px'}} action={() => setModalActive(false)} />
                    <Button text={'Да'} style={{padding: '12px 40px'}} action={() => handleSubmit('true')} disabled={!sumInput} />
                </div>
            </LUI.TextForModal>
        </Modal>}
        <LUI.BlockDispute>
          <LUI.DisputeCard>
            <LUI.DisputeCardHead>
              Оспорить платеж
            </LUI.DisputeCardHead>
            <LUI.DisputeCardInput>
              <label>ID платежа</label>
              <input
                  type="text"
                  value={idInput}
                  placeholder="Введите ID"
                  onChange={(e) => setIdInput(e.target.value)}
              />
            </LUI.DisputeCardInput>
            <LUI.DisputeCardFileForm>
              <label>Чек №1</label>
              <div
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop1}
                  onClick={() => ref1.current && ref1.current.click()}
              >
                <input
                    onChange={handleFileInput1}
                    ref={ref1}
                    type="file"
                    id="fileInput1"
                    placeholder="Файл не выбран"
                    accept="image/bmp, image/jpg, image/png, image/gif, image/webp, image.jpeg, application/pdf"
                    multiple={false}
                    style={{ display: 'none' }}
                />
                <span>{file1 ? file1.name : "Выбрать файл"}</span>
              </div>
            </LUI.DisputeCardFileForm>
            <LUI.DisputeCardFileForm>
              <label>Чек №2</label>
              <div
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop2}
                  onClick={() => ref2.current && ref2.current.click()}
              >
                <input
                    onChange={handleFileInput2}
                    ref={ref2}
                    type="file"
                    id="fileInput2"
                    placeholder="Файл не выбран"
                    accept="image/bmp, image/jpg, image/png, image/gif, image/webp, image.jpeg, application/pdf"
                    multiple={false}
                    style={{ display: 'none' }}
                />
                <span>{file2 ? file2.name : "Выбрать файл"}</span>
              </div>
            </LUI.DisputeCardFileForm>
            <LUI.DisputeCardInput>
              <label>Комментарий</label>
              <input
                type='text'
                value={comment}
                placeholder="Введите комментарий"
                onChange={(e) => setComment(e.target.value)}
              />
            </LUI.DisputeCardInput>
            <Button text="Оспорить" action={handleCheckDispute} disabled={!idInput || (!file1 && !file2)} />
          </LUI.DisputeCard>
        </LUI.BlockDispute>
      </>
  );
};




export default DisputePayment
