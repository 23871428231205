import React, { type CSSProperties, type ReactNode } from 'react'
import './Modal.css'

interface ModalProps {
  active: boolean
  setActive: (status: boolean) => void
  style: CSSProperties
  children: ReactNode
  className: string
}
const Modal: React.FC<ModalProps> = ({ active, setActive, style, className, children }) => {
  return (
    <div className={active ? 'modal active' : 'modal'}>
      <div
        className={active ? 'modal_content active' + className : 'modal_content' + className}
        onClick={(e) => {
          e.stopPropagation()
        }}
        style={style}
      >
        <p>
          <img
            className={'close_modal_cross_img'}
            src='/cross_icon.png'
            alt={'cross_icon'}
            onClick={() => {
              setActive(false)
            }}
          />
        </p>
        {children}
      </div>
    </div>
  )
}

export default Modal
