import React from 'react'
import Container from "../../components/MainContainerWithWindow/Container";
import {ErrorMessagePage} from "../../components/ErrorWrapper/ErrorMessagePage";

export const ServerError = () => {
  return (
    <Container>
      <ErrorMessagePage errorCode={'500'} errorText={'Ошибка на сервере'} />
    </Container>
  )
}
