import React, {useState} from 'react'
// @ts-ignore
import styles from './Transfer.module.css'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetMerchantOrderQuery } from '../../../redux/services/MerchantApi'
import { Loading } from '../../../components/Loading/Loading'
import Timer from '../../../components/Timer/Timer'
import CopyToClipboard from 'react-copy-to-clipboard'
import {failStatuses, successStatuses} from "../../../utils/orders_statuses";
import {TRANSFER_STATUS} from "../../../utils/routes";
import Container from "../../../components/MainContainerWithWindow/Container";

export const Transfer = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [isCopyCardVisible, setCopyAlertVisible] = useState<boolean>(false)
  const [isCopyAmountVisible, setIsCopyAmountVisible] = useState<boolean>(false)
  const {
    data: order,
    isLoading: isOrderLoading,
      isSuccess
  } = useGetMerchantOrderQuery(id!,{ pollingInterval: 1000})
  const status = order?.status
  const handleCopyCardClick = () => {
    setCopyAlertVisible(true)
    setTimeout(() => {
      setCopyAlertVisible(false)
    }, 2000)
  }
  const handleCopyAmountClick = () => {
    setIsCopyAmountVisible(true)
    setTimeout(() => {
      setIsCopyAmountVisible(false)
    }, 2000)
  }
  const isTransfer = order?.requisite.payment_type === 'TRANSFER'
  const formatPhoneNumber = (phoneNumber: string | undefined) => {
    if (!phoneNumber) return '';

    const phoneStr = phoneNumber.toString();
    let cleanedPhoneStr = phoneStr;

    let formattedNumber = '';

    if (phoneStr.startsWith('+7')) {
      formattedNumber += '+';
      cleanedPhoneStr = phoneStr.slice(1);
    }

    const part1 = cleanedPhoneStr.slice(0, 1);
    const part2 = cleanedPhoneStr.slice(1, 4);
    const part3 = cleanedPhoneStr.slice(4, 7);
    const part4 = cleanedPhoneStr.slice(7, 9);
    const part5 = cleanedPhoneStr.slice(9, 11);


    formattedNumber += `${part1}(${part2})${part3}-${part4}-${part5}`;

    return formattedNumber;
  };

  const redirect = () => navigate(`${TRANSFER_STATUS}/${id}`)
  if (successStatuses.includes(String(status)) || failStatuses.includes(String(status))) {
    redirect()
  }
  if (isOrderLoading) {
    return <Loading />
  }
  return (
    <Container>
      <div className={styles.wrapper}>
        <h1>Сделайте перевод по указанным реквизитам</h1>
        <p className={styles.warningRed}>
          <img src={'/error_icon.png'} alt={'error icon'} />
          <p>Автоматическое зачисление платежа осуществляется по указанной ниже точной сумме</p>
        </p>
        {order?.status === 'REQUISITES_NOT_FOUND' && (
          <p className={styles.warningRed}>{order?.status}</p>
        )}
        <div className={styles.info}>
          <div className={styles.item}>
            <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
              <div>
                <span>Банк получателя</span>
                <p>{order?.requisite.bank.name || 'Банк не найден'}</p>
              </div>
              {order?.requisite.payment_type === 'SBP' && <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <img src={'/sbp.svg'} alt={'SBP'} style={{width: '22px', height: '22px'}}/>
              </div>}
            </div>
          </div>
          <div className={styles.item}>
            <div>
              <span>{isTransfer ? 'Номер счета' : 'Реквизиты для перевода'}</span>
              <p>{isSuccess && order?.requisite.payment_type === 'SBP' ? formatPhoneNumber(order?.requisite.phone_number) : order?.requisite.account_number}</p>
            </div>
            <CopyToClipboard text={order?.requisite.payment_type === 'SBP' ?
                (order?.requisite.phone_number ? order.requisite.phone_number.toString() : '') :
                (order?.requisite.account_number ? order.requisite.account_number.toString() : '')}>
            <img
                src={isCopyCardVisible ? '/copy-submitted-icon.png' : '/copy_icon.png'}
                alt={'copy icon'}
                onClick={() => {
                  handleCopyCardClick()
                }}
              />
            </CopyToClipboard>
          </div>
          {isTransfer &&
            <>
            <div className={styles.item}>
            <div>
              <span>БИК</span>
              <p>{order?.requisite.bank.bic ?? '-'}</p>
            </div>
            <CopyToClipboard text={order?.requisite.bank.bic}>
              <img
                src={isCopyCardVisible ? '/copy-submitted-icon.png' : '/copy_icon.png'}
                alt={'copy icon'}
                onClick={() => {
                  handleCopyCardClick()
                }}
              />
            </CopyToClipboard>
          </div>
            <div className={styles.item}>
              <div>
                <span>Назначение платежа</span>
                <p>Перевод</p>
              </div>
              <CopyToClipboard text={'Перевод'}>
                <img
                  src={isCopyCardVisible ? '/copy-submitted-icon.png' : '/copy_icon.png'}
                  alt={'copy icon'}
                  onClick={() => {
                    handleCopyCardClick()
                  }}
                />
              </CopyToClipboard>
            </div>
            <div className={styles.item}>
              <div>
                <span>Получатель</span>
                <p>{order?.requisite.fio}</p>
              </div>


            </div>
            </>}
          <div className={styles.item}>
            <div>
              <span>Сумма для перевода</span>
              <p>
                {order?.expected_income} {order?.currency_code_to}
              </p>
            </div>
            <CopyToClipboard text={order?.expected_income.toString() || ''}>
              <img
                src={isCopyAmountVisible ? '/copy-submitted-icon.png' : '/copy_icon.png'}
                alt={'copy icon'}
                onClick={() => {
                  handleCopyAmountClick()
                }}
              />
            </CopyToClipboard>
          </div>
        </div>
        <p className={styles.notFilledItem}>
          <span>Номер заявки</span>
          <p>{id}</p>
        </p>
        {Number(order?.time_left) <= 600 &&
          <p className={styles.warning}>
            <img src={'/pending_icon.png'} alt={'pending icon'}/>
              Ваша заявка ожидает подтверждения
            </p>
        }
        <div className={styles.info}>
          <div className={styles.item}>
            <div>
              <span>Ожидаем ваш платеж</span>
              <Timer seconds={Number(order?.time_left)} className={styles.timer}/>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
