import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getMerchantTokenFromLocalStorage } from '../../utils/functions/getToken'
import {
  type TCreateOrderRequest,
  type TCreateOrderResponse,
  type TGetOrderResponse,
} from '../types/MerchantTypes'
import {backendUrl} from "../../utils/url";

export const MerchantApi = createApi({
  reducerPath: 'MerchantApi',
  tagTypes: ['Merchant'],
  baseQuery: fetchBaseQuery({ baseUrl: `${backendUrl}/merchant` }),
  endpoints: (build) => ({
    getMerchantOrder: build.query<TGetOrderResponse, string>({
      query: (orderId: string) => ({
        url: `check_order/${orderId}/`,
        method: 'GET',
      }),
    }),
    createOrder: build.mutation<TCreateOrderResponse, TCreateOrderRequest>({
      query: (body) => ({
        url: 'create_order/?type=api',
        method: 'POST',
        body,
        headers: { Authorization: `Token ${getMerchantTokenFromLocalStorage()}` },
      }),
    }),
    cancelOrder: build.mutation<TGetOrderResponse, string>({
      query: (orderId) => ({
        url: `cancel_order/${orderId}/`,
        method: 'GET',
        headers: { Authorization: `Token ${getMerchantTokenFromLocalStorage()}` },
      }),
    }),
  }),
})

export const { useGetMerchantOrderQuery, useCreateOrderMutation, useCancelOrderMutation } = MerchantApi
