import {backendUrl} from "../../utils/url";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {TEmailRequest} from "../types/EmailTypes";

export const EmailApi = createApi({
    reducerPath: 'EmailApi',
    tagTypes: ['Email'],
    baseQuery: fetchBaseQuery({ baseUrl: `${backendUrl}` }),
    endpoints: (build) => ({
        sendEmail: build.mutation<void, TEmailRequest>({
            query: (body: TEmailRequest) => ({
                url: '/resend_landing_email/',
                method: 'POST',
                body,
            }),
        }),
    }),
})

export const { useSendEmailMutation } = EmailApi
