import Header from "./components/Header/Header";
import Content from "./components/Content/Content";
import About from "./components/About/About";
import Working from "./components/Working/Working";
import Compatibility from "./components/Compatibility/Compatibility";
import Integration from "./components/Integration/Integration";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import * as UI from './styled'

const Main = () => {
    return (
        <>
            <Header/>
            <UI.Container>
                <Content/>
                <About/>
                <Working/>
                <Compatibility/>
                <Integration/>
                <Contact/>
            </UI.Container>
            <Footer/>
        </>

    )
}

export default Main