import * as UI from './styled'
import {CONTACTS} from "../../../../../utils/refs";
const Button = (props : {isSubmit?: boolean, isButton? : boolean }) => {
    const {isSubmit = false, isButton = false} = props
    return (
        <UI.Container>
            {isButton ?
                <button type={isSubmit ? 'submit' : 'button'}>Связаться с нами</button> :
                <a href={CONTACTS}>Связаться с нами</a>
            }
        </UI.Container>
    )
}

export default Button