import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form'
// @ts-ignore
import styles from './CreateOrder.module.css'
import { ControlledInput } from '../../../components/ControlledInput/ControlledInput'
import { Button } from '../../../components/Button/Button'
import { useCreateOrderMutation } from '../../../redux/services/MerchantApi'
import { Loading } from '../../../components/Loading/Loading'
import { Selector } from '../../../components/Selector/Selector'
import { useGetCheckActivePaymentsQuery, useGetCurrencyQuery } from '../../../redux/services/CurrencyApi'
import Container from '../../../components/MainContainerWithWindow/Container';
import setFavicon from '../../../utils/setFavIcon';

interface TInputs {
  amount: string
  currency: string
  requisite_type: 'C2C' | 'SBP' | 'TRANSFER'
}

export const CreateOrder = () => {
  const formMethods = useForm<TInputs>({
    mode: 'onSubmit',
    shouldUnregister: false,
  })
  const { handleSubmit, setError } = formMethods
  const [createOrder, { isLoading: isCreatingLoading }] = useCreateOrderMutation()
  const { data: currencyList = [], isLoading: isCurrencyLoading } = useGetCurrencyQuery()
  const { data: checkActive } = useGetCheckActivePaymentsQuery(undefined, {
    pollingInterval: 5000
  })

  useEffect(() => {
    const requisiteType = checkActive?.C2C ? 'C2C' : checkActive?.SBP ? 'SBP' : 'TRANSFER'
    formMethods.setValue('requisite_type', requisiteType)
  }, [checkActive]);

  const navigate = useNavigate()
  const onSubmit: SubmitHandler<TInputs> = async (data) => {
    if (!data.currency) {
      setError('currency', { type: 'custom', message: 'select currency!' })
    } else {
      const amount = parseInt(data.amount.replaceAll(' ', ''))
      const body = {
        expected_income: amount,
        currency_code_from: 'USDT',
        currency_code_to: data.currency,
        requisite_type: data.requisite_type
      }
      await createOrder(body)
        .unwrap()
        .then((response) => {
          navigate(`/transfer/${response.id}`)
        })
        .catch(() => {
          setError('amount', { type: 'custom', message: 'amount is too large' })
        })
    }
  }

  useEffect(() => {
    setFavicon('/favicon_order.svg');
  }, []);

  if (isCreatingLoading || isCurrencyLoading) {
    return <Loading />
  }
  return (
    <FormProvider {...formMethods}>
      <Container>
        <div className={styles.wrapper}>
          <h1>Введите сумму пополнения</h1>
          <p>
            <img src={'/error_icon.png'} alt={'error icon'} />
            Вы пополняете свой кошелек через систему переводов
          </p>
          <div className={styles.limits}>
            Лимиты для пополнения в RUB: от 500 до 300 000
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.formContainer}>
              <ControlledInput
                  title={'Сумма для перевода'}
                  blockStyle={{width: '362px'}}
                  placeholder={'Введите сумму'}
                  rules={{required: true}}
                  name={'amount'}
                  type={'text'}
                  errorText={'Сумма не попадает в установленный лимит'}
                  transform={(text: string | undefined) => {
                    if (text) {
                      return text
                        .replace(/[^0-9]/g, '')
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                    }
                    return text
                  }}
              />
              <Selector
                  blockStyle={{width: '100%', flex: '1'}}
                  title={'Валюта'}
                  defaultValue={currencyList[0]?.name || ''}
                  placeholder={''}
                  name={'currency'}
                  items={currencyList}
              />
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '20px'}}>
              <h4>Метод оплаты</h4>
              <label htmlFor="sbp" style={{display: 'flex', gap: '4px', cursor: 'pointer'}}>
                <input
                  {...formMethods.register('requisite_type', {required: true})}
                  type="radio"
                  value="SBP"
                  id="sbp"
                  disabled={!checkActive?.SBP}
                  defaultChecked
                  style={{accentColor: '#1890FF'}}
                />
                СБП
              </label>
              <label htmlFor="c2c" style={{display: 'flex', gap: '4px', cursor: 'pointer'}}>
                <input
                  {...formMethods.register('requisite_type')}
                  type="radio"
                  disabled={!checkActive?.C2C}
                  value="C2C"
                  id="c2c"
                  style={{accentColor: '#1890FF'}}
                />
                Банковская карта
              </label>
              <label htmlFor="transfer" style={{display: 'flex', gap: '4px', cursor: 'pointer'}}>
                <input
                  {...formMethods.register('requisite_type')}
                  type="radio"
                  disabled={!checkActive?.TRANSFER}
                  value="TRANSFER"
                  id="transfer"
                  style={{accentColor: '#1890FF'}}
                />
                Перевод
              </label>
            </div>
            <Button
              text={'Пополнить'}
              type={'submit'}
              disabled={!checkActive?.SBP && !checkActive?.C2C}
              style={{
                width: '100%', marginTop: '24px',
                background: !checkActive?.SBP && !checkActive?.C2C && !checkActive?.TRANSFER ? 'gray' : '#1890FF',
                border: !checkActive?.SBP && !checkActive?.C2C && !checkActive?.TRANSFER ? '1px solid white' : 'auto'
              }}
            />
            {!checkActive?.SBP && !checkActive?.C2C && !checkActive?.TRANSFER && <p>
              В системе нет активных рабочих реквизитов
            </p>}
          </form>
        </div>
      </Container>
    </FormProvider>
  )
}
