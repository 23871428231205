import styled from "styled-components";

export const Blocks = styled.div`
    display: flex;
    gap : 10px;
    min-height: 234px;
    @media (max-width: 768px) {
        height: fit-content;
        flex-direction: column;
        gap : 24px;
    }
`
export const FilledBlock = styled.div`
    border-radius: 10px;
    background: #1890FF;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #FFF;
    font-weight: 400;
    h4 {
        font-size: 20px;
        line-height: 26px;
    }
    p {
        font-size: 18px;
        line-height: 26px;
    }
    @media (max-width: 1024px) {
        h4 {
            font-size: 18px;
        }
        p {
            font-size: 14px;
            line-height: 20px;
        }
    }
    @media (max-width: 768px) {
        gap : 20px;
        h4 {
            font-size: 20px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
        }
    }
`

export const Block = styled.div`
    border-radius: 10px;
    background: rgba(24, 144, 255, 0.05);
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap : 40px;
    h3 {
        color: #2C2E35;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
    }
    div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap : 7px;
        p {
            display: flex;
            justify-content: center;
            gap : 7px;
            border-radius: 28px;
            background: #FFF; 
            padding: 8px 13px;
            color: #1F6AD9;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
        }
        span {
            color: #696E7E;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            width: 160px;
        }
    }
    @media (max-width: 1024px) {
        h3 {
            font-size: 18px;
        }
        div {
            gap : 6px;
            p {
                gap : 6px;
            }
        }
    }
    @media (max-width: 768px) {
        h3 {
            font-size: 20px;
            margin-bottom: 20px;
        }
        div {
            gap : 8px;
            p {
                gap : 8px;
            }
        }
    }
`