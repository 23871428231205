import { useNavigate, useParams } from 'react-router-dom'
import { useGetMerchantOrderQuery } from '../../../redux/services/MerchantApi'
import { Loading } from '../../../components/Loading/Loading'
import React from 'react'
import { SuccessTransfer } from './components/SuccessTransfer/SuccessTransfer'
import { FailTransfer } from './components/Fail Transfer/FailTransfer'
import {failStatuses, successStatuses} from "../../../utils/orders_statuses";

export type TStatusProps = {
  url : string
}

const TransferStatus = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: order, isLoading: isOrderLoading } = useGetMerchantOrderQuery(id!)
  const status = order?.status
  if ( status && !successStatuses.includes(String(status)) && !failStatuses.includes(String(status))) {
    navigate('/')
  }
  if (isOrderLoading) {
    return <Loading />
  }
  return (
    <>
      {successStatuses.includes(String(status)) && <SuccessTransfer url={String(order?.merchant_success_page)} />}
      {failStatuses.includes(String(status)) && <FailTransfer url={String(order?.merchant_failed_page)} />}
    </>
  )
}

export default TransferStatus
