
export const MERCHANT_AUTH = '/merchant_auth'
export const CREATE_ORDER = '/create-order'
export const TRANSFER = '/transfer'
export const TRANSFER_STATUS = '/transfer-status'

export const SERVER_ERROR = '/server-error'
export const FORBIDDEN_ERROR = '/forbidden-error'
export const NOT_FOUND_ERROR = '/not-found-error'

export const DISPUTE_PAYMENT = '/dispute_payment'