import * as UI from './styled'
import {CSSProperties} from "react";

type TTitleProps = {
    titleText : string
    infoText : string
    style?: CSSProperties
}
const Title = (props : TTitleProps) => {
    const {titleText, infoText, style ={}} = props
    return (
        <UI.Title style={style}>
            <h1>{titleText}</h1>
            <p>{infoText}</p>
        </UI.Title>
    )
}

export default Title