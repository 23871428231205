import {RegisterOptions, useFormContext} from "react-hook-form";
import * as UI from './styled'

type TInputProps = {
    placeholder: string
    name: string
    rules?: RegisterOptions
    transform?: (text: string | undefined) => string | undefined
}
export type TErrorProps = {
    isError: boolean
}
const Input = (props : TInputProps) => {
    const {placeholder, rules, name, transform} = props
    const {
        register,
        formState: { errors, isValid },
        setValue,
        resetField
    } = useFormContext()
    const transformInput = (text: string | undefined) => {
        if (transform && text) {
            setValue(name, transform(text))
        }
    }
    return (
        <UI.Container isError={!!errors?.[name]}>
            <input
                type={'text'}
                placeholder={placeholder}
                {...register(name, rules)}
                onChange={(e) => {
                    transform && transformInput(e.target.value)
                }}
            />
            <img src={'/crossIcon.svg'} alt={'cross'} onClick={() => resetField(name)}/>
        </UI.Container>
    )
}

export default Input