import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {backendUrl} from "../../utils/url";

export const DisputesApi = createApi({
  reducerPath: 'DisputesApi',
  tagTypes: ['Disputes'],
  baseQuery: fetchBaseQuery({
    baseUrl: backendUrl,
    headers: { },
  }),
  endpoints: (builder) => ({
    postDispute: builder.mutation<any, any>({
      query: (body) => ({
        url: `disputes/`,
        method: 'POST',
        body,
        headers: {}
      })
    }),
    checkDispute: builder.query<any, any>({
      query: (id) => ({
        url: `disputes/check_order_on_dispute/${id}/`,
        method: 'GET',
        headers: {}
      })
    }),
    checkMerchantDispute: builder.query<any, any>({
      query: (id) => ({
        url: `disputes/check_order_on_dispute_by_merchant_order_id/${id}/`,
        method: 'GET',
        headers: {}
      })
    }),
  }),
})

export const {
    usePostDisputeMutation, useCheckDisputeQuery, useCheckMerchantDisputeQuery
} = DisputesApi
