import Title from "../common/Title/Title";
import * as UI from './styled'
import {ABOUT_ID} from "../../../../utils/id";

export const About = () => {
    return (
        <div id={ABOUT_ID}>
            <Title titleText={'О нас'} infoText={'Мы специализируемся на High Risk сегменте: принимаем платежи со всего мира и гарантируем безопасность капитала при развитии вашего бизнеса.'}/>
            <UI.Block>
                <div>
                    <img src={'/conversionIcon.svg'} alt={'conversion icon'}/>
                    <p>Конверсия до 99%</p>
                    <span>Высокий процент успешных транзакций.</span>
                </div>
                <div>
                    <img src={'/fastPaymentsIcon.svg'} alt={'fast payments icon'}/>
                    <p>Быстрый вывод средств (Т+О)</p>
                    <span>Простой вывод денег без задержек.</span>
                </div>
                <div>
                    <img src={'/insuranceIcon.svg'} alt={'insurance icon'}/>
                    <p>Страхование средств</p>
                    <span>Безопасность средств через предоплаченный депозит.</span>
                </div>
                <div>
                    <img src={'/managerIcon.svg'} alt={'manager icon'}/>
                    <p>Личный менеджер</p>
                    <span>Доступ к чату с личным менеджером 24/7.</span>
                </div>
            </UI.Block>
        </div>
    )
}
export default About