// @ts-ignore
import styles from './MerchantAuth.module.css'
import { type SubmitHandler, useForm, FormProvider } from 'react-hook-form'
import React, { useState } from 'react'
import { Button } from '../../../components/Button/Button'
import { useAuthMutation } from '../../../redux/services/AuthApi'
import { Loading } from '../../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'
import { BaseInput } from '../../../components/BaseInput/BaseInput'
import Container from "../../../components/MainContainerWithWindow/Container";
import {CREATE_ORDER} from "../../../utils/routes";

interface TInputs {
  username: string
  password: string
}

const MerchantAuth = () => {
  const formMethods = useForm<TInputs>({
    mode: 'onSubmit',
    shouldUnregister: false,
  })
  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = formMethods
  const token = sessionStorage.getItem('merchant_token')
  const navigate = useNavigate()
  const [authRequest, { isLoading }] = useAuthMutation()
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
  const handlePasswordVisible = () => {
    setPasswordVisible((prevState) => {
      return !prevState
    })
  }
  const onSubmit: SubmitHandler<TInputs> = async (data) => {
    await authRequest(data)
      .unwrap()
      .then((response) => {
        const token = response.auth_token
        sessionStorage.setItem('merchant_token', token)
        navigate('/create-order')
        window.location.reload()
      })
      .catch(() => {
        setError('root', { type: 'custom', message: 'credentials does`not exist' })
      })
  }
  if (token) {
    navigate(CREATE_ORDER)
  }
  if (isLoading) {
    return <Loading />
  }
  return (
    <FormProvider {...formMethods}>
      <Container>
        <div className={styles.wrapper}>
          <h1>Введите логин и пароль</h1>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <BaseInput
              title={''}
              placeholder={'Логин'}
              rules={{ required: true }}
              name={'username'}
              type={'text'}
              imgLeft={errors.username || errors.root ? '/user_icon_red.png' : '/user_icon.png'}
              imgLeftWidth={24}
            />
            {errors.username && <p>Введите имя пользователя</p>}
            <BaseInput
              imgLeft={errors.password ? '/password_icon_red.png' : '/password_icon.png'}
              imgLeftWidth={24}
              imgRight={passwordVisible ? '/eye_icon_active.png' : '/eye_icon.png'}
              imgRightAction={handlePasswordVisible}
              title={''}
              placeholder={'Пароль'}
              rules={{ required: true }}
              name={'password'}
              type={passwordVisible || errors.root ? 'text' : 'password'}
              blockStyle={{ marginTop: '12px' }}
              imgRightWidth={18}
            />
            {errors.password && <p>Введите пароль</p>}
            {errors.root && <p>Неверный логин или пароль</p>}
            <Button
              text={'Вход'}
              type={'submit'}
              disabled={false}
              style={{ width: '100%', marginTop: '24px' }}
            />
          </form>
        </div>
      </Container>
    </FormProvider>
  )
}

export default MerchantAuth
