import Title from "../common/Title/Title";
import * as UI from './styled'
import Input from "../common/Input/Input";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {CONTACTS_ID} from "../../../../utils/id";
import {useSendEmailMutation} from "../../../../redux/services/EmailApi";
type TInputs = {
    name : string
    email : string
    tg_nickname : string
}
export type TButtonProps = {
    send : boolean
}
export const Contact = () => {
    const [send, setSend] = useState<boolean>(false)
    const [sendEmail] = useSendEmailMutation()
    const [mobile, setMobile] = useState<boolean>(false)
    useEffect(() => {
        const handleResize = () => {
            setMobile( window.innerWidth <= 768)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    const formMethods = useForm<TInputs>({
        mode: 'onSubmit',
        shouldUnregister: false
    })
    const { handleSubmit, formState : {errors}, reset } = formMethods
    const onSubmit: SubmitHandler<TInputs> = async (data) => {
        await sendEmail(data)
            .unwrap()
            .then(() => {
                setSend(true);
                reset()
            })
            .catch()
    }
    return (
        <div id={CONTACTS_ID}>
            <FormProvider {...formMethods}>
                <Title titleText={'Контакты'} infoText={'Оставьте cвои контактные данные и мы свяжемся с вами'} style={{alignItems : "center"}}/>
                <UI.Block onSubmit={handleSubmit(onSubmit)}>
                        <h3>Форма обратной связи</h3>
                        <UI.TopBlock>
                            <UI.LeftContainer>
                                <Input name={'name'} placeholder={'Ваше имя'} rules={{
                                    minLength: 1,
                                    maxLength: 255,
                                    required: true
                                }}/>
                                <Input name={'email'} placeholder={'Электронная почта'} rules={{
                                    minLength: 5,
                                    maxLength: 255,
                                    required: true,
                                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                }}
                                       transform={(text: string | undefined) => {
                                           if (text) {
                                               const formattedText = text
                                                   .slice(0, 255);
                                               return formattedText
                                           }
                                           return text
                                       }}/>
                                <Input name={'tg_nickname'} placeholder={'Ник в телеграме'} rules={{
                                    minLength: 1,
                                    maxLength: 255,
                                    required: true
                                }}/>
                            </UI.LeftContainer>
                            {!mobile && <UI.Icon src={'/contactIcon.png'} alt={'contact'}/>}
                        </UI.TopBlock>
                    <UI.Info>
                        <UI.Button disabled={send} type={'submit'} send={send}>{ send ? 'Заявка отправлена' : 'Связаться с нами'}{send && <img src={'/okIcon.svg'} alt={'ok'}/>}</UI.Button>
                        <p>Нажимая на кнопку «Cвязаться с нами», я даю согласие на обработку персональных данных, а
                            также соглашаюсь с политикой конфиденциальности.</p>
                    </UI.Info>
                    {mobile && <UI.Icon src={'/contactIcon.png'} alt={'contact'}/>}
                </UI.Block>
            </FormProvider>
        </div>
    )
}
export default Contact