import * as UI from './styled'
import Button from "../common/Button/Button";
export const Content = () => {
    return (
        <UI.Container>
            <UI.LeftBlock>
                <h1>Платежи со всего мира для вашего бизнеса без границ</h1>
                <p>Оптимизируйте бизнес с помощью наших инновационных платёжных решений, специально разработанных для проектов из High Risk сегмента.</p>
                <Button/>
            </UI.LeftBlock>
            <UI.RightBlock>
                <img src={'/content.png'} alt={'content'}/>
            </UI.RightBlock>
        </UI.Container>
    )
}
export default Content