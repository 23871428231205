import styled from "styled-components";

export const Blocks = styled.div`
    display: flex;
    gap : 24px;
    @media (max-width: 1024px) {
        gap : 15px;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        gap : 24px;
    }
`

export const Item = styled.div`
    position: relative;
    background : rgba(24, 144, 255, 0.05);
    width: 33%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    height: 450px;
    .number {
        height: 60px;
        width: fit-content;
        margin-bottom: 32px;
    }
    h4 {
        color: #2C2E35;
        font-size: 30px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 20px;
    }
    p {
        color: #696E7E;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
    }
    @media (max-width: 1024px) {
        height: 380px;
            .number {
                height: 43px;
                margin-bottom: 22px;
            }
            h4 {
                font-size: 20px;
                margin-bottom: 14px;
            }
            p {
                font-size: 14px;
                line-height: 20px;
            }
    }
    @media (max-width: 768px) {
        height: 350px;
            width: auto;
            .number {
                height: 40px;
                margin-bottom: 20px;
            }
            h4 {
                margin-bottom: 8px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
            }
    }
`

export const Info = styled.span`
    display: flex;
    flex-direction: column;
    margin-top : 20px;
    gap : 5px;
    color: #696E7E;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    img {
        height: 32px !important;
        width: 32px !important;
        margin: 0 !important;
    }
    @media (max-width: 1024px) {
        font-size: 12px;
        line-height: 16px;
        margin-top: 14px;
        img {
            height: 22px !important;
            width: 22px !important;
        }
    }
    @media (max-width: 768px) {
        margin-top: 20px;
    }
`

export const Contact = styled.div`
    margin-top: auto;
    display: flex;
    justify-content: center;
    img {
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1000;
    }
    button {
        width: 100%;
    }
    @media (max-width: 1024px) {
        img {
            bottom: -20px;
        }
    }
`

export const Lk = styled.img`
    border-radius: 5px; 
    z-index: -1000;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 80%;
    height: fit-content;
    @media (max-width: 768px) {
        left: 50%;
        transform: translateX(-50%); 
    }
`