import { configureStore } from '@reduxjs/toolkit'
import { MerchantApi } from './services/MerchantApi'
import { CurrencyApi } from './services/CurrencyApi'
import {AuthApi} from "./services/AuthApi";
import {EmailApi} from "./services/EmailApi";
import {errorCatcherMiddleware} from "./middleware";
import {DisputesApi} from "./services/DisputesApi";

export const store = configureStore({
  reducer: {
    [MerchantApi.reducerPath]: MerchantApi.reducer,
    [CurrencyApi.reducerPath]: CurrencyApi.reducer,
    [AuthApi.reducerPath]: AuthApi.reducer,
    [EmailApi.reducerPath]: EmailApi.reducer,
    [DisputesApi.reducerPath]: DisputesApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(errorCatcherMiddleware)
      .concat(MerchantApi.middleware)
      .concat(CurrencyApi.middleware)
      .concat(AuthApi.middleware)
      .concat(EmailApi.middleware)
      .concat(DisputesApi.middleware)
})
