const setFavicon = (iconUrl: string): void => {
  let link = document.querySelector("link[rel*='icon']") as HTMLLinkElement | null;

  if (!link) {
    link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    document.head.appendChild(link);
  }

  link.href = iconUrl;
};

export default setFavicon;
