// @ts-ignore
import styles from './Selector.module.css'
import React, { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useOnClickOutside } from '../../utils/hooks/useOnClickOutside'

interface ISelectorProps {
  title: string
  placeholder?: string
  name: string
  required?: boolean
  blockStyle?: React.CSSProperties
  items: TItem[]
  defaultValue?: string
}

export interface TItem {
  name: string
  id: string
}

export const Selector = (props: ISelectorProps) => {
  const { title, items, blockStyle, name, required, placeholder, defaultValue } = props
  const {
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext()
  const [open, setOpen] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<string | undefined>(getValues(name))
  const menuRef = useRef(null)
  const handleOpen = () => {
    setOpen((prevState) => {
      return !prevState
    })
  }
  const handleClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue)
      setSelectedValue(defaultValue)
    }
  }, [defaultValue, name, setValue])
  useOnClickOutside(menuRef, handleClose)
  return (
    <div className={styles.inputBlock} style={blockStyle} ref={menuRef}>
      <div className={styles.title}>
        {required && <span style={{ color: '#FE5B37' }}>*</span>}
        {title}
      </div>
      <div
        className={errors?.[name] ? `${styles.selectorHead} ${styles.error}` : styles.selectorHead}
        onClick={() => {
          handleOpen()
        }}
      >
        {selectedValue ? (
          <div className={styles.selected}>{selectedValue}</div>
        ) : (
          <div className={styles.placeholder}>{placeholder}</div>
        )}
        <img src={open ? '/arrow_up_icon.png' : '/arrow_down_icon.png'} alt={'arrow'} />
      </div>

      {open && (
        <div className={styles.selectorWrapper}>
          <div className={styles.selector}>
            {items.map((item) => {
              return (
                <div className={styles.option} key={item.id}>
                  <label htmlFor={item.id}>
                    <input
                      defaultChecked={defaultValue === item.id}
                      type={'radio'}
                      id={item.id}
                      value={item.name || defaultValue}
                      onClick={() => {
                        setSelectedValue(item.name)
                        setOpen(false)
                      }}
                      {...register(name, { value: defaultValue })}
                    />
                    {item.name}
                  </label>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
