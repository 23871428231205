import * as UI from './styled'
import {ABOUT, WORKING, CONTACTS, INTEGRATION} from "../../../../utils/refs";
import {lktraderUrl} from "../../../../utils/url";
export const Header = () => {
    return (
        <UI.Header>
            {/*<img src={'./container-logo.svg'} alt={'logo'} style={{height: '50px'}}/>*/}
            <UI.Menu>
                <a href={ABOUT}>О нас</a>
                <a href={WORKING}>Деятельность</a>
                <a href={INTEGRATION}>Интеграция</a>
                <a href={CONTACTS}>Контакты</a>
            </UI.Menu>
            <UI.Link href={`${lktraderUrl}`} target="_blank">Личный кабинет</UI.Link>
        </UI.Header>
    )
}
export default Header