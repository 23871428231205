
import { type TAuthRequest, type TAuthResponse } from '../types/AuthTypes'
import {backendUrl} from "../../utils/url";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const AuthApi = createApi({
  reducerPath: 'AuthApi',
  tagTypes: ['Auth'],
  baseQuery: fetchBaseQuery({ baseUrl: `${backendUrl}/auth` }),
  endpoints: (build) => ({
    auth: build.mutation<TAuthResponse, TAuthRequest>({
      query: (body: TAuthRequest) => ({
        url: 'token/login/',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useAuthMutation } = AuthApi
