import styled from "styled-components";

export const Container = styled.div`
    @media (max-width: 768px) {
        h1, p {
            color : #FFF; !important;
        }
        background: #1890FF;
        padding: 40px 16px 67px;
        margin: 0 -16px;
    }
`

export const Block = styled.div`
    display: flex;
    gap : 24px;
    white-space: nowrap;
    flex-wrap: wrap;
    p { 
        padding: 16px 24px;
        border-radius: 10px;
        background: rgba(24, 144, 255, 0.05);
        color: #2C2E35;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px; 
    }
    @media (max-width: 1024px) {
        gap : 0;
        border-radius: 10px; 
        background: rgba(24, 144, 255, 0.05);
        p {
            background-color: transparent;
            border-radius: 0;
            font-size: 16px;
            line-height: 24px; 
        }
    }
    @media (max-width: 768px) {
        gap : 8px;
        background-color: transparent;
        p {
            padding: 8px 16px;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.15);
            color: #FFF;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }
`