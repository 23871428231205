import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    gap : 100px;
    @media (max-width : 1024px) {
        & {
            gap : 38px;
        }
    }
    @media (max-width : 768px) {
        & {
            flex-direction: column;
        }
    }
`
export const LeftBlock = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap : 32px;
    h1 {
        color: #2C2E35;
        font-size: 35px;
        font-weight: 400;
        line-height: 140%; 
    }
    p {
        color: #696E7E;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
    }
    @media (max-width : 1024px) {
        p {
            font-size: 18px;
        }
    }
    @media (max-width : 768px) {
        & {
            gap : 24px;
        }
        h1 {
            font-size: 26px;
            line-height: 30px; 
        }
        p {
            font-size: 16px;
            line-height: 24px
        }
    }
`
export const RightBlock = styled.div`
    img {
        width: 544px;
        height: auto;
    }
    @media (max-width : 1024px) {
        img {
            width: 382px;
        }
    }
    @media (max-width : 768px) {
        img {
            width: 100%
        }
    }
    
`