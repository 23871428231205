export const statusIds = ['BN', 'WP', 'IP', 'TO', 'MV', 'SF', 'FR', 'NF', 'AA', 'UR', 'CR', 'SF,AA']
export const statusesTranslate = [
  'Новая заявка',
  'Ожидают платежа',
  'В процессе',
  'Просроченные',
  'Ожидает проверки админом',
  'Успешно завершенные',
  'Завершенные с перерасчетом',
  'Заявка не найдена',
  'Автоматически одобрена',
  'Заявка с перерасчетом',
  'Перерасчет отклонен',
  'Успешно завершенные',
]

export const statuses = ['BRAND_NEW', 'IN_PROGRESS', 'TIME_OUT', 'SUCCESSFULLY_FINISHED', 'FINISHED_WITH_RECALCULATION', 'REQUISITES_NOT_FOUND', 'AUTO_APPROVED', 'UNFINISHED_WITH_RECALCULATION', 'CANCELED_WITH_RECALCULATION']

export const successStatuses = ['SUCCESSFULLY_FINISHED','FINISHED_WITH_RECALCULATION','AUTO_APPROVED']
export const failStatuses = ['UNFINISHED_WITH_RECALCULATION','TIME_OUT','CANCELED_WITH_RECALCULATION', 'REQUISITES_NOT_FOUND']
