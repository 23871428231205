import Title from "../common/Title/Title";
import * as UI from './styled'
export const Compatibility = () => {
    return (
        <UI.Container>
            <Title titleText={'Совместимость'} infoText={'Наша платформа идеально подходит любой сфере'} style={{alignItems : "center"}}/>
            <UI.Block>
                <p>🎰 Gambling</p>
                <p>🏀 Betting</p>
                <p>💱 Exchange</p>
                <p>💃 Dating</p>
                <p>💸 Crypto</p>
                <p>🔞 Adult</p>
                <p>💰 Banking</p>
            </UI.Block>
        </UI.Container>
    )
}
export default Compatibility