import styled from 'styled-components'
export const BlockDispute = styled.div`
    background-color: #F8F6FA;
    height: 100vh;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    color: #A7A7A7;
    justify-content: center;
`

export const DisputeCard = styled.div`
    padding: 30px;
    background-color: white;
    max-width: 370px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 10px #37296108;
    box-sizing: border-box;
`

export const DisputeCardHead = styled.div`
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: black;
`

export const DisputeCardInput = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    input {
        width: calc(100% - 10px);
        border: 1px solid #EAEAEA;
        height: 40px;
        padding-left: 10px;
        border-radius: 6px;
        color: black;
        &[type=number] {
            -moz-appearance: textfield;
        }
        &[type=number]::-webkit-outer-spin-button,
        &[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
    label {
        font-size: 12px;
        color: black;
        line-height: 1;
        margin-bottom: 10px;
    }
`

export const DisputeCardFileForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    label {
        font-size: 12px;
        color: black;
        line-height: 1;
        margin-bottom: 10px;
    }
    div {
        background-color: #f9f9f9;
        width: 100%;
        height: 100px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed gray;
        transition: background-color 0.3s ease;
        position: relative;
        cursor: pointer;
        text-align: center;

        &.dragover {
            background-color: rgba(0, 0, 0, 0.2);
        }

        input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            background: transparent;
            cursor: pointer;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
        }

        span {
            position: absolute;
            pointer-events: none;
            color: black;
            text-align: center;
        }
    }

    .file-name {
        color: black;
        margin-top: 5px;
    }
`

export const TextForModal = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    h1 {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
    }
    h2 {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px
    }
    span {
        color: #1f2937;
        word-break: break-word;
        font-size: 24px;
        font-weight: 800;
        line-height: 28px;
    }
`


