import styled from "styled-components";
import {TErrorProps} from "./Input";

export const Container = styled.div<TErrorProps>`
    position: relative;
    height: 52px;
    input {
        padding: 16px;
        border-radius: 10px;
        background: rgba(24, 144, 255, 0.05);
        border: ${({ isError }) => isError ? '1px solid #FE5B37' : 'none'};
        width: 100%;
        font-size: 17px;
        font-weight: 400;
        line-height: 20px;
        box-sizing: border-box;
    } 
    input:focus {
        outline: none;
    }
    input::placeholder {
        color: #696E7E;
        font-size: 17px;
        font-weight: 400;
        line-height: 20px; 
    }
    img {
        right: 16px;
        position: absolute;
        transform: translate(0, -50%);
        top: 50%;
    }
    img:hover {
        cursor: pointer;
    }
`