import styled from "styled-components";

export const Footer = styled.footer`
    background: #2C2E35;
    color: #696E7E;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
`