import { type Middleware, type MiddlewareAPI, isRejected } from '@reduxjs/toolkit'
import {FORBIDDEN_ERROR, NOT_FOUND_ERROR, SERVER_ERROR} from "../utils/routes";
export const errorCatcherMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejected(action)) {
    // @ts-ignore
    if (action.payload?.status === 404) {
      if (!window.location.href.includes("dispute_payment")) {
        window.location.href = NOT_FOUND_ERROR
      }
    }
    // @ts-ignore
    if (action.payload?.status === 403) {
      window.location.href = FORBIDDEN_ERROR
    }
    // @ts-ignore
    if (action.payload?.status === 500) {
      window.location.href = SERVER_ERROR
    }
  }
  return next(action)
}
